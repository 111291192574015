<template>
    <div class="min-vh-100 bg-light">
        <div class="d-flex justify-content-center align-items-center m-3">
            <b-row class="app-login-box d-block mx-0 justify-content-center justify-center" v-if="recoveryToken">
                <div class="d-flex justify-content-center justify-center w-auto">
                    <img src="@/assets/images/logo-login.png" height="100px" class="d-block img-fluid logo-img" />

                </div>
                <div class="mx-auto mb-3"/>
                <div class="modal-dialog w-100 mx-auto">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="h5 modal-title text-center">
                                <h4 class="mt-2">
                                    <span>Set a new password</span>
                                </h4>
                            </div>
                            <password-reset-form :token="recoveryToken" />
                        </div>
                    </div>
                </div>
            </b-row>
            <b-row class="app-login-box d-block m-3 justify-content-center justify-center" v-else>
                <div class="d-flex justify-content-center justify-center w-auto">
                    <img src="@/assets/images/logo-login.png" height="100px" class="d-block img-fluid logo-img" />

                </div>
                <div class="mx-auto mb-3"/>

                <div class="modal-dialog w-100 mx-auto" v-if="emailAddress">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="h5 modal-title text-center">
                                <h4 class="mt-2">
                                    <span>Email has been sent to {{ emailAddress }}. Do not forget to check your Spam inbox.</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="modal-dialog w-100 mx-auto">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="h5 modal-title text-center mb-3">
                                    <h4 class="my-2">
                                        <span>Lost password?</span>
                                    </h4>
                                    <span>Enter your email and we'll send you a link to get back into your account.</span>
                                </div>
                                <password-recovery-form @formSubmit="onSubmit"/>
                            </div>
                        </div>
                    </div>
                </div>
            </b-row>
        </div>
    </div>
</template>
<script>
import PasswordRecoveryForm from "@/components/UserPages/PasswordRecoveryForm";
import PasswordResetForm from "@/components/UserPages/PasswordResetForm";

export default {
    components: {
        PasswordRecoveryForm,
        PasswordResetForm,
    },
    data: () => ({
        slickOptions6: {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            initialSlide: 0,
            autoplay: true,
            adaptiveHeight: true
        },

        slide: 0,
        sliding: null,
        email: '',
        recoveryToken: null,
        emailAddress: ''
    }),
    mounted() {
        if (this.$route.params.token) {
            this.recoveryToken = this.$route.params.token
        }
    },
    methods: {
        onSubmit(emailAddress) {
            this.emailAddress = emailAddress
        },
        handleClick(newTab) {
            this.currentTab = newTab;
        },
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        reInit() {
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },

        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        }
    },
};
</script>

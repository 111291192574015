<template>
    <b-form  >
        <b-row  >
            <b-col md="12" >
                <b-form-group class="w-100">
                    <b-form-input id="email" type="email" name="email" placeholder="Email here..." v-model="form.email" required />
                </b-form-group>
            </b-col>
            <b-col md="12" v-if="error">
                <b-form-group>
                    <div class="alert alert-danger" role="alert">
                        {{error}}
                    </div>
                </b-form-group>
            </b-col>
            <b-col md="12">
                <div class="ml-auto">
                    <v-btn color="primary" class="text-unset" @click="onSubmit">Send link</v-btn>
                </div>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "PasswordRecoveryForm",
    data() {
        return {
            form: {
                email: ''
            },
            error: null
        }
    },
    methods: {
        onSubmit() {
            this.$apollo.mutate({
                mutation: gql`mutation($email:String!){sendPasswordRecovery(email:$email)}`,
                variables: {
                    email: this.form.email
                }
            }).then((result) => {
                if (result.data.sendPasswordRecovery) {
                    this.$emit('formSubmit', this.form.email);
                }
                else {
                    this.error = "Invalid email address";
                }
            })
        }
    }
}
</script>

<style scoped>
    .form-element {
        min-height: 42px;
        top: auto;
    }
</style>
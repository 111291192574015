<template>
    <b-form >
        <b-row >
            <b-col md="6" >
                <b-form-group
                    label="Enter new password"
                    description="Your password should contain at least 6 characters"
                >
                    <b-form-input id="password-1" type="password" name="password-1" placeholder="Password" v-model="form.password1" required />
                </b-form-group>
            </b-col>
            <b-col md="6" >
                <b-form-group
                    label="Confirm new password"
                >
                    <b-form-input id="password-1" type="password" name="password-1" placeholder="Repeat password" v-model="form.password2" required v-validate="'required|confirmed:password1'" />
                </b-form-group>
            </b-col>
            <b-col md="12" v-if="error">
                <b-form-group>
                    <div class="alert alert-danger" role="alert">
                        {{error}}
                    </div>
                </b-form-group>
            </b-col>
            <b-col md="12" >
                <div class="ml-auto">

                    <v-btn color="primary" class="text-unset" @click="onSubmit">Reset password</v-btn>
                </div>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "PasswordResetForm",
    props: [
        "token"
    ],
    data() {
        return {
            form: {
                password1: '',
                password2: ''
            },
            error: null
        }
    },
    methods: {
        onSubmit() {
            this.error = null
            if (this.form.password1 !== this.form.password2) this.error  = "Passwords do not match"
            if (this.form.password1.length < 6) this.error = "Password should contain at least 6 characters"
            if (this.error) return
            this.$apollo.mutate({
                mutation: gql`mutation($token:String!,$password:String!){changePasswordByToken(token:$token,newPassword:$password){error,token,user{id,username,email,firstName}}}`,
                variables: {
                    password: this.form.password1,
                    token: this.token
                }
            }).then(() => {
                this.$router.push("/login");
            })
        }
    }
}
</script>

<style scoped>
.form-element {
    min-height: 42px;
    top: auto;
}
</style>